@import '../node_modules/@syncfusion/ej2-base/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/fluent.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/fluent.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/fluent.css";


html {
  position: relative;
  min-height: 100%;
}

body {
  padding: 0;
}

/* Override Bootstrap Styling */

.btn-primary {
  background-color: #025fce !important;
}

